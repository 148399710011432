// app config
export type VendorKey = string;
export type AppKey = string;
export type AppFullKey = string;
export type AppName = string;
export type AppOrigin = string;
export type AppUrl = string;

export interface AppIntent {
  fullKey: IntentFullKey,
  legacyFullKey: IntentLegacyFullKey,
  vendorKey: VendorKey,
  appKey: AppKey,
  intentKey: IntentKey,
  version: IntentVersion,
}

export interface AppConfig {
  vendorKey: VendorKey,
  appKey: AppKey,
  fullKey: AppFullKey,
  origin: AppOrigin,
  url: AppUrl,
  name: AppName,
  // TODO iframe config (sandbox, allow, scrolling, ...)
  system: boolean,
  navigator: boolean,
  embedded: boolean,
  closeable: boolean,
  autoClose: boolean,
  intents?: AppIntent[],
  route?: string,
}

export type WidgetKey = string;
export type WidgetFullKey = string;
export type WidgetName = string;
export type WidgetOrigin = string;
export type WidgetUrl = string;
export type WidgetFormat = '1x1' | '1x2' | '2x1' | '2x2';

export interface WidgetConfig {
  fullKey: WidgetFullKey,
  vendorKey: VendorKey,
  appKey: AppKey,
  appFullKey: AppFullKey,
  widgetKey: WidgetKey,
  name: WidgetName,
  origin: WidgetOrigin,
  url: WidgetUrl,
  format: WidgetFormat,
}

export interface IntentConfig {
  fullKey: IntentFullKey,
  legacyFullKey: IntentLegacyFullKey,
  vendorKey: VendorKey,
  appKey: AppKey,
  intentKey: IntentKey,
  version: IntentVersion,
  name: IntentName,
  type: IntentType,
  popupMode?: IntentPopupMode,
}


// general messages
export enum MessageType {
  // access token
  ACCESS_TOKEN_REQUEST = 'access-token-request',
  ACCESS_TOKEN_RESULT = 'access-token-result',
  // outgoing intent
  OUTGOING_INTENT_REQUEST = 'outgoing-intent-request',
  OUTGOING_INTENT_RESULT = 'outgoing-intent-result',
  // ingoing intent
  INGOING_INTENT = 'ingoing-intent',
  // context info
  CONTEXT_INFO = 'context-info',
}

export type MessageId = string;

export interface Message {
  type: MessageType;
  id: MessageId;
  data: AccessTokenRequest
    | AccessTokenResult
    | OutgoingIntentRequest
    | OutgoingIntentResult
    | IngoingIntent
    | ContextInfo
  ;
}


// access token
export type AccessToken = string;

export interface AccessTokenRequest {
}

export interface AccessTokenRequestMessage extends Message {
  type: MessageType.ACCESS_TOKEN_REQUEST,
  data: AccessTokenRequest,
}

export enum AccessTokenResultError {
  UNKNOWN = 'unknown',
  // TODO add other errors
}

export interface AccessTokenResult {
  success: boolean,
  accessToken?: AccessToken,
  message?: string,
  error?: AccessTokenResultError,
}

export interface AccessTokenResultMessage extends Message {
  type: MessageType.ACCESS_TOKEN_RESULT,
  data: AccessTokenResult,
}


// intent base
export type IntentKey = string;
export type IntentFullKey = string;
export type IntentLegacyFullKey = string;
export type IntentVersion = number;
export type IntentName = string;
export type IntentType = 'normal' | 'popup' | 'broadcast';
export type IntentPopupMode = 'small' | 'medium' | 'large';

export interface Intent {
  id: IntentFullKey,
  version: IntentVersion,
  data: any,
}


// outgoing intent
export interface OutgoingIntentRequest extends Intent {
  id: IntentFullKey,
  version: IntentVersion,
  data: any,
}

export interface OutgoingIntentRequestMessage extends Message {
  type: MessageType.OUTGOING_INTENT_REQUEST,
  data: OutgoingIntentRequest,
}

export enum OutgoingIntentResultError {
  UNKNOWN = 'unknown',
  // TODO add other errors
}

export interface OutgoingIntentResult {
  success: boolean;
  message?: string;
  // TODO klären ob nötig
  // durch die Id kann eine App nachvollziehen welche Apps der Anwender nutzt
  // Hierdurch kann eine Art profiling stattfinden
  error?: OutgoingIntentResultError;
  targetAppFqn?: AppFullKey;
}

export interface OutgoingIntentResultMessage extends Message {
  type: MessageType.OUTGOING_INTENT_RESULT,
  data: OutgoingIntentResult,
}


// intent result
export interface IngoingIntent extends Intent {
  id: IntentFullKey,
  legacyId: IntentLegacyFullKey,
  version: IntentVersion,
  data: any,
}

export interface IngoingIntentMessage extends Message {
  type: MessageType.INGOING_INTENT,
  data: IngoingIntent,
}

// Event emitter access token
export interface EmitAccessTokenRequest {
  appFqn: AppFullKey,
  accessTokenRequest: AccessTokenRequest,
  resultCallback: (result: AccessTokenResult) => void,
}

// Event emitter intent
export interface EmitOutgoingIntentRequest {
  appFqn: AppFullKey,
  intent: OutgoingIntentRequest,
  resultCallback: (result: OutgoingIntentResult) => void,
}

// Event emitter close
export interface EmitCloseRequest {
  appFqn: AppFullKey,
}

export interface EmitContextInfoPropertyRequest {
  appFqn: AppFullKey,
  key: string,
  value: ContextInfoProperty,
}

export enum WellKnownShellProperties {
  APP_MODE_NORMAL = 'shell:app-mode:normal',
  APP_MODE_POPUP = 'shell:app-mode:popup',
  APP_MODE_WIDGET = 'shell:app-mode:widget',
  EMBEDDED_EMBEDDED = 'shell:embedded:embedded',
  EMBEDDED_EMBEDDING = 'shell:embedded:embedding',
}

export enum WellKnownJustFarmingProperties {
  ANALYTICS = 'vendor:de.just-farming:analytics',
  ERROR_REPORTING = 'vendor:de.just-farming:errorReporting',
  ANALYTICS_VID = 'vendor:de.just-farming:analytics.visitorId',
}

export type WellKnownProperties = WellKnownShellProperties | WellKnownJustFarmingProperties;
export type PropertyKey = string | WellKnownProperties;

export type Property = boolean | string | number | undefined;
export type ArrayProperty = Property[];
export type ContextInfoProperty = Property | ArrayProperty;

export interface ContextInfo {
  properties: Map<PropertyKey, ContextInfoProperty>;
}

export interface ContextInfoMessage
  extends Message {
  type: MessageType.CONTEXT_INFO,
  data: ContextInfo,
}
